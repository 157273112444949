//////画像切り替え
$(function () {
  var width = $(window).width();
  if( width < 640 ){
    $("img").each(function(){
      $(this).attr("src", $(this).attr("src").replace("_pc","_sp"));
    })
  }
});
//////ナビゲーション
$(function(){
  $('.menu_trigger').on('click',function(){
    $(this).toggleClass('active');
    $('.header_nav_contents').slideToggle();
  });
});
$(function() {
    $('.menu_trigger').click(function(){
        $('.header_contents').toggleClass('active');
    });
});
$('.header_nav_contents a').on('click', function(){
        if (window.innerWidth <= 900) {
            $('.menu_trigger').click();
        }
    });	

$(window).on('resize', function() {
	if( 'none' == $('.header_nav_contents').css('display') ){
		$('.header_nav_contents').attr('style','');
	}
});

//////スムーススクロール
$(function(){
  $('a[href^="#"]').click(function(){
    var speed = 1500 ;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });
});

//////ページトップ
$(function () {
  var topBtn = $('#page-top');
  topBtn.hide();
  $(window).scroll(function () {
    if ($(this).scrollTop() > 650) {
      topBtn.fadeIn();
    } else {
      topBtn.fadeOut();
    }
  });
  topBtn.click(function () {
    $('body,html').animate({
      scrollTop: 0
    }, 1000);
    return false;
  });
});
// case
$(function () {
  $(".case_img_sub img").on("click", function () {
    img = $(this).attr("src");
    $(".case_img_main img").fadeOut(50, function () {
      $(".case_img_main img")
        .attr("src", img)
        .on("load", function () {
          $(this).fadeIn();
        });
    });
  });
});
// consept
$(function() {
  $(".con_link").hover(
    function () {
     $(".con_img").addClass("ac_cc");
     },
     function () {
     $(".con_img").removeClass("ac_cc");
    }
  );
});
$(function() {
  $(".con_img").hover(
    function () {
     $(".con_link").addClass("ac_cc02");
     },
     function () {
     $(".con_link").removeClass("ac_cc02");
    }
  );
});
$(function() {
  $(".con_link02").hover(
    function () {
     $(".con_img02").addClass("ac_cc");
     },
     function () {
     $(".con_img02").removeClass("ac_cc");
    }
  );
});
$(function() {
  $(".con_img02").hover(
    function () {
     $(".con_link02").addClass("ac_cc03");
     },
     function () {
     $(".con_link02").removeClass("ac_cc03");
    }
  );
});

//////スライダー
$(function () {
  $('#slider01').slick({
    infinite: true,
    arrows: true,
    variableWidth: true,
    autoplaySpeed: 5000,
    centerMode: true,
    asNavFor: '#slider02',
    prevArrow: '<div class="prev"></div>',
    nextArrow: '<div class="next"></div>',
    responsive: [{
      breakpoint: 900,
      settings: {
        autoplay: true,
        variableWidth: false,
        centerMode: false
      }
    }]
  });
  $('#slider02').slick({
    infinite: true,
    arrows: false,
    slidesToShow: 3,
    variableWidth: true,
    autoplaySpeed: 5000,
    asNavFor: '#slider01',
    focusOnSelect: true,
    responsive: [{
      breakpoint: 900,
      settings: {
        autoplay: true,
      }
    }]
  });
});
$(function () {
  $('#slider03').slick({
    infinite: true,
    arrows: true,
    variableWidth: true,
    autoplaySpeed: 5000,
    centerMode: true,
    asNavFor: '#slider04',
    prevArrow: '<div class="prev"></div>',
    nextArrow: '<div class="next"></div>',
    responsive: [{
      breakpoint: 900,
      settings: {
        autoplay: true,
        variableWidth: false,
        centerMode: false
      }
    }]
  });
  $('#slider04').slick({
    infinite: true,
    arrows: false,
    slidesToShow: 3,
    variableWidth: true,
    autoplaySpeed: 5000,
    asNavFor: '#slider03',
    focusOnSelect: true,
     responsive: [{
      breakpoint: 900,
      settings: {
        autoplay: true,
      }
    }]
  });
});

// アニメーション
function fadeAnime(){
	// fadeUp
	$('.fadeUpTrigger').each(function(){
		var elemPos = $(this).offset().top-50;
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();
		if (scroll >= elemPos - windowHeight){
		$(this).addClass('fadeUp');
		}else{
		$(this).removeClass('fadeUp');
		}
  });
  $('.fadedownTrigger').each(function(){
		var elemPos = $(this).offset().top-50;
		var scroll = $(window).scrollTop();
		var windowHeight = $(window).height();
		if (scroll >= elemPos - windowHeight){
		$(this).addClass('fadedown');
		}else{
		$(this).removeClass('fadedown');
		}
  });
}
	$(window).scroll(function (){
		fadeAnime();
	});
	$(window).on('load', function(){
		fadeAnime();
	});